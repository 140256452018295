import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@about/env/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getJuryComment(source: string) {
    return this.http.get(this.baseUrl + source + '/jury_comments/');
  }

  getTestimonyHosts() {
    return this.http.get(`${this.baseUrl}testimony_hosts/`);
  }

  getTestimonials(host: string = '') {
    return this.http.get(`${this.baseUrl}testimony/?host=${host}`);
  }
}
