import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@about/app/core/api/contact/contact.service';
import _ from 'lodash';
import { HttpEventType } from '@angular/common/http';
import { Report, ReportArchType } from '@sparro/app/core/models/report.model';
import { SnackbarService } from '@sparro/app/core/helpers/toastr/snackbar.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  contactInfoControl: FormControl = new FormControl();
  requesterNameControl: FormControl = new FormControl();
  requesterEmailControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ]);
  categories: any = [];
  selectedType: any;
  selectedCategory: any;
  concernText: string = 'Select your concern';
  media: any;
  mediaName: string = '';
  uploadMessage = 'Upload PDF/IMAGE';
  uploadingMessage: string;
  loadingMedia: boolean = false;
  submittingRequest: boolean = false;

  uploadProgress: number = 0;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public metaService: MetaService,
    private contactService: ContactService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
    this.getCategories();
    this.updateMetaInfo();
    this.initializeBreakpoints();
  }

  getCategories() {
    this.contactService.getcontactCategories().subscribe(
      response => {
        this.categories = _.orderBy(response, 'title', 'asc');
      },
      err => {
        console.log(err);
      }
    );
  }

  reset() {
    this.mediaName = '';
    this.media = null;
    this.contactInfoControl.setValue('');
    this.requesterNameControl.setValue('');
    this.requesterEmailControl.setValue('');
    this.selectedType = null;
    this.selectedCategory = null;
    this.concernText = 'Select your concern';
    this.uploadMessage = 'Upload PDF/IMAGE';
  }

  setConcernText(type) {
    this.concernText = type.title;
  }

  setContactType(type, category) {
    this.selectedType = type;
    this.selectedCategory = category;
    this.setConcernText(type ? type : category);
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  uploadFile(event) {
    const files = event.target.files;
    for (const item of files) {
      if (item.size > 15000000) {
      } else {
        this.loadingMedia = true;
        this.startFileUpload(item);
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = _event => {
          this.mediaName = item.name;
        };
      }
    }
  }

  startFileUpload(file: any) {
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('description', '');
    formData.append('file', file, file.name);
    formData.append('type', file.type);
    this.uploadQuery(formData);
  }

  uploadQuery(formData) {
    this.userService.uploadUserMedia(formData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((event.loaded / event.total) * 100);
          this.uploadingMessage = 'File uploading... ' + this.uploadProgress.toString() + '%';
        } else if (event.type === HttpEventType.Response) {
          this.media = event.body;
          this.uploadMessage = 'Update uploaded PDF/IMAGE';
          this.loadingMedia = false;
          this.uploadProgress = 0;
          this.snackbarService.success('Media added successfully!');
        }
      },
      err => {
        console.log(err);
        this.loadingMedia = false;
        this.snackbarService.error('Please upload a valid file.');
      }
    );
  }

  canSubmit() {
    return Boolean(
      (this.user || (this.requesterEmailControl.valid && this.requesterNameControl.value)) &&
        this.selectedCategory &&
        !this.loadingMedia &&
        !this.submittingRequest
    );
  }

  requestToContact(): void {
    if (this.canSubmit()) {
      const data = {
        requester: this.user ? this.user.id : null,
        requester_name: this.user
          ? `${this.user.first_name} ${this.user.last_name}`
          : this.requesterNameControl.value,
        requester_email: this.user ? `${this.user.email}` : this.requesterEmailControl.value,
        type: this.selectedType ? this.selectedType.id : null,
        category: this.selectedCategory.id,
        additional_info: this.contactInfoControl.value ? this.contactInfoControl.value : '',
        media: this.media ? this.media.id : null
      };
      this.submittingRequest = true;
      this.contactService.createContactRequest(data).subscribe(
        (response: any) => {
          this.reset();
          this.submittingRequest = false;
          this.snackbarService.success('Contact request added successfully!');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  updateMetaInfo(): void {
    const title = 'Contact us - UNI';
    const description = `Submit your request if you are facing issues in using services at UNI.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
