<div class="features-container">
  <ng-container *ngIf="!smallView">
    <div class="image-container">
      <uni-image
        [src]="featuredProject.cover.src"
        [alt]="featuredProject.cover.alt"
        [width]="580"
        [height]="870"
        auxClassList="key-feature-image">
      </uni-image>
      <span class="sparro-grey-300 font-sm margin-1"
        >Visual from
        <a [href]="featuredProject.url" target="_blank" class="sparro-grey-300 no-decor-hover">{{
          featuredProject.title
        }}</a>
      </span>
    </div>
  </ng-container>
  <div class="content-container">
    <h2 class="title margin-1 sparro-teal-900 overflow-hidden">Build your presence</h2>
    <ng-container *ngIf="smallView; else sliderView">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <ng-container *ngTemplateOutlet="featureCardsView"> </ng-container>
      </div>
    </ng-container>
    <ng-template #sliderView>
      <div class="carousel overflow-hidden relative">
        <div class="slider" #slider (transitionend)="transitionEnd($event)">
          <ng-container *ngTemplateOutlet="featureCardsView"> </ng-container>
        </div>
        <div class="controls">
          <div class="next">
            <button class="fab-button mat-mini-fab" (click)="scrollNext()">
              <i class="icon-arrow-right-bold"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #featureCardsView>
  <ng-container *ngIf="smallView">
    <div class="image-container">
      <uni-image
        [src]="featuredProject.cover.src"
        [alt]="featuredProject.cover.alt"
        [height]="850"
        [width]="560"
        auxClassList="key-feature-image">
      </uni-image>
      <span class="sparro-grey-300 font-sm margin-1"
        >Visual from
        <a [href]="featuredProject.url" target="_blank" class="sparro-grey-300 no-decor-hover">{{
          featuredProject.title
        }}</a>
      </span>
    </div>
  </ng-container>
  <ng-container *ngFor="let feature of features">
    <section class="margin-1" #cards>
      <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="flex-start center">
        <uni-image
          [src]="feature.icon"
          [alt]="feature.title ? feature.title : 'feature icon'"
          [height]="48"
          [width]="48"
          classes="fit-cover"></uni-image>
        <h3 class="feature-title text-left margin-0">{{ feature.title }}</h3>
      </div>
      <p
        class="feature-text text-left sparro-grey-800 padding-x-1 margin-0"
        [innerHtml]="feature.text | safeContent"></p>
      <ng-container *ngIf="feature.url; else disabledView">
        <a [href]="feature.url" class="no-decor">
          <button mat-button class="button-green">
            <span class="feature-text">Get Strated</span>
          </button>
        </a>
      </ng-container>
      <ng-template #disabledView>
        <button mat-button class="button-green" [disabled]="true">
          <span class="feature-text">Coming Soon</span>
        </button>
      </ng-template>
    </section>
  </ng-container>
</ng-template>
