<ng-container *ngIf="juryComments?.length">
  <div class="juror-comment-container" fxLayout="column">
    <div
      fxLayout="row"
      [fxLayoutAlign]="showFixedCard ? 'space-between center' : 'center center'"
      fxLayoutGap="16px">
      <div fxLayout="column" class="title-section">
        <h2 class="title margin-0">Live feedback from industry experts</h2>
        <p class="subtitle sparro-grey-800 margin-0">
          Your gateway to <span class="sparro-grey-900 font-bolder">open</span> and
          <span class="sparro-grey-900 font-bolder">transparent</span> competitions
        </p>
      </div>
      <div class="comment-card fixed-card" #commentSection *ngIf="showFixedCard">
        <div fxLayout="column" fxLayoutGap="24px">
          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
            <uni-user-avatar [user]="juryComments[0].user" [viewProfile]="false"> </uni-user-avatar>
            <div fxLayout="column" fxLayoutGap="5px">
              <p class="margin-0 username">
                {{ juryComments[0].user.first_name | titlecase }}
                {{ juryComments[0].user.last_name | titlecase }}
              </p>
              <p class="user-detail margin-0">{{ juryComments[0].user.bio | titlecase }}</p>
            </div>
          </div>
          <p class="margin-0 font-bold sparro-grey-800 comment">“{{ juryComments[0].comment }}</p>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="sparro-grey-800 font-bold font-xs margin-0">
            <a
              [href]="getProjectUrl(juryComments[0].project.slug)"
              target="_blank"
              class="no-decor-hover sparro-grey-800">
              {{ juryComments[0].project.title | titlecase | truncate : 20 }}
            </a>
            <p class="sparro-grey-800 font-bold font-xs margin-0">
              {{ juryComments[0].updated_at | date : 'd MMM' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card-container">
      <ng-container *ngFor="let comment of juryComments.slice(1, juryComments.length)">
        <div #commentSection class="comment-card" [class.card-border]="comment.score">
          <div fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
              <uni-user-avatar [user]="comment.user" [viewProfile]="false"> </uni-user-avatar>
              <div fxLayout="column" fxLayoutGap="5px">
                <p class="username margin-0">
                  {{ comment.user.first_name | titlecase }} {{ comment.user.last_name | titlecase }}
                </p>
                <p class="user-detail margin-0">{{ comment.user.bio | titlecase }}</p>
              </div>
            </div>
            <p class="margin-0 font-bold sparro-grey-800 comment">“{{ comment.comment }}</p>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="sparro-grey-800 font-bold font-xs margin-0">
              <a
                [href]="getProjectUrl(comment.project.slug)"
                target="_blank"
                class="no-decor-hover">
                {{ comment.project.title | titlecase | truncate : 20 }}
              </a>
              <p class="sparro-grey-800 font-bold font-xs margin-0">
                {{ comment.updated_at | date : 'd MMM' }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
