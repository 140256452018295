import { FEATURES, Image, SHOWCASE } from '@about/app/core/configs/landing.config';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

export class KeyFeatureDetails {
  title: string;
  icon: string;
  url?: string;
  text: string;
}

export class ProjectDetail {
  cover: Image;
  title: string;
  url: string;
}
@Component({
  selector: 'app-key-features',
  templateUrl: './key-features.component.html',
  styleUrls: ['./key-features.component.scss']
})
export class KeyFeaturesComponent implements OnInit {
  @Input() smallView: boolean = false;

  features: KeyFeatureDetails[] = FEATURES.KEY;
  featuredProject: ProjectDetail = SHOWCASE.PROJECTS[0];

  next: boolean = false;

  @ViewChild('slider') slider: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  scrollNext(): void {
    if (this.slider) {
      this.slider.nativeElement.style.transform = 'translate(-382px)';
      this.next = true;
    }
  }

  transitionEnd(e: Event): void {
    if (this.slider && this.next) {
      this.features.push(this.features.shift());
      this.slider.nativeElement.style.transition = 'none';
      this.slider.nativeElement.style.transform = 'translate(0)';
      setTimeout(() => {
        this.slider.nativeElement.style.transition = 'all 0.5s';
      });
      this.next = !this.next;
    }
  }
}
