import { CommentService } from '@about/app/core/api/comment/comment.service';
import { environment } from '@about/env/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { User } from '@sparro/app/core/models/user.model';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { FEATURES } from '@about/app/core/configs/landing.config';

@Component({
  selector: 'app-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.scss']
})
export class TestimoniesComponent implements OnInit {
  testimonies: any;
  featureImages: string[] = FEATURES.IMAGES;
  hosts: any;
  activeHost: any;
  user: User;
  smallView: boolean = false;

  @ViewChildren('testimonySection') testimonials: QueryList<any>;

  constructor(
    private commentService: CommentService,
    public breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
    this.getHosts();
    this.updateMetaInfo();
    this.initializeBreakpoints();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  changeActiveHost(host) {
    this.activeHost = host;
    this.getTestimonies();
  }

  getHosts() {
    this.commentService.getTestimonyHosts().subscribe(
      response => {
        this.hosts = response;
        this.activeHost = response[0];
        this.getTestimonies();
      },
      err => {
        console.log(err);
      }
    );
  }

  getTestimonies(): void {
    this.commentService.getTestimonials(this.activeHost.title).subscribe(
      response => {
        this.testimonies = response;
      },
      err => {
        console.log(err);
      }
    );
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  checkCardVisibility(): void {
    if (window.innerWidth > 960) {
      const windowTop = window.pageYOffset;
      const windowBottom = window.innerHeight + window.pageYOffset;
      this.testimonials.forEach(testimony => {
        const testimonyYMid =
          testimony.nativeElement.offsetTop + testimony.nativeElement.offsetHeight / 2;
        testimony.nativeElement.style.opacity =
          windowTop < testimonyYMid && windowBottom > testimonyYMid ? '1' : '0';
      });
    }
  }

  getProfileUrl(username: string): string {
    return `${environment.profileUrl}${username}`;
  }

  updateMetaInfo(): void {
    const title = 'Testimonials - UNI';
    const description = `First featured testimonial would be the description - Name & position of the user`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/testimony/testimonyCover.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
