import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@about/env/environment';

@Injectable({
  providedIn: 'root'
})
export class JournalService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getJournalsByType(typeId: number) {
    return this.http.get(this.baseUrl + 'blogs/?types=' + typeId);
  }

  getJournalsByUserId(userId: number) {
    return this.http.get(this.baseUrl + 'blogs/?user_id=' + userId);
  }
}
