<div class="header-section sparro-white">
  <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
  <div fxLayout="row" fxLayoutAlign="center center" class="container">
    <p class="margin-0 title">Contact Us</p>
  </div>
</div>
<div
  fxLayout="column"
  fxLayoutGap="80px"
  fxLayoutAlign="center center"
  class="inner-container margin-auto">
  <div class="helpdesk-section padding-2" fxLayout="column" fxLayoutAlign="center center">
    <uni-image
      class="saly-img margin-top-10 margin-bottom-15"
      src="https://uni-common-assets.s3.us-east-1.amazonaws.com/images/contact-us-saly.svg"
      classes="width-100 height-100">
    </uni-image>
    <p class="margin-y-2 font-bold font-md sparro-grey-800 text-center">
      Did you check our knowledge base?
    </p>
    <p class="margin-y-2 font-sm sparro-grey-800 text-center">
      Explore our knowledge base article if it already has a solution to your problem.
    </p>
    <a href="https://help.uni.xyz" class="no-decor margin-y-6">
      <uni-navigation-button default="Visit Helpdesk" button_class="primary">
      </uni-navigation-button>
    </a>
  </div>
  <p class="font-lg font-bolder">OR</p>
  <div fxLayout="column" fxLayoutGap="64px" fxLayoutAlign="end center" class="width-100">
    <p class="font-bolder font-lg">Submit a request</p>
    <form fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="center center" class="form-width">
      <ng-container *ngIf="user; else noUser">
        <div fxLayout="column" fxLayoutGap="16px">
          <p class="margin-0">Email ID<span class="sparro-red-700">*</span></p>
          <input matInput [placeholder]="user.email" class="input-section" [disabled]="true" />
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
          <p class="margin-0">Your name<span class="sparro-red-700">*</span></p>
          <input
            matInput
            [placeholder]="user.first_name + ' ' + user.last_name"
            class="input-section"
            [disabled]="true" />
        </div>
      </ng-container>
      <ng-template #noUser>
        <div fxLayout="column">
          <p class="margin-bottom-4 margin-top-0">Email ID<span class="sparro-red-700">*</span></p>
          <input
            matInput
            placeholder="Your Email ID"
            type="email"
            class="input-section"
            [formControl]="requesterEmailControl" />
          <mat-hint class="sparro-grey-800 font-xs">{{
            requesterEmailControl.value
              ? requesterEmailControl.valid
                ? ''
                : 'Please add a valid email address'
              : 'Required'
          }}</mat-hint>
        </div>
        <div fxLayout="column">
          <p class="margin-bottom-4 margin-top-0">Your name<span class="sparro-red-700">*</span></p>
          <input
            matInput
            placeholder="Your full name"
            type="text"
            class="input-section"
            [formControl]="requesterNameControl" />
          <mat-hint class="sparro-grey-800 font-xs">{{
            requesterNameControl.value ? '' : 'Required'
          }}</mat-hint>
        </div>
      </ng-template>
      <div fxLayout="column">
        <p class="margin-bottom-4 margin-top-0">Concern<span class="sparro-red-700">*</span></p>
        <ng-container *ngIf="categories">
          <mat-expansion-panel class="expansion-panel mat-elevation-z0 input-section">
            <mat-expansion-panel-header>
              <input matInput [value]="concernText" [disabled]="true" />
            </mat-expansion-panel-header>
            <mat-divider style="margin-bottom: 4px; margin-top: 12px"></mat-divider>
            <ng-container *ngFor="let category of categories">
              <ng-container *ngIf="category.types.length; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="margin-0 section sparro-grey-800">
                      {{ category.title }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let type of category.types">
                    <p
                      class="margin-0 inner-section sparro-grey-800"
                      (click)="setContactType(type, category)">
                      {{ type.title }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p
                  class="margin-0 inner-section sparro-grey-800"
                  (click)="setContactType(null, category)">
                  {{ category.title }}
                </p>
              </ng-template>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
        <mat-hint class="sparro-grey-800 font-xs">{{
          selectedCategory?.id ? '' : 'Required'
        }}</mat-hint>
      </div>
      <div fxLayout="column" fxLayoutGap="16px">
        <p class="margin-0">Description</p>
        <textarea
          matInput
          placeholder="Your message"
          cdkTextareaAutosize
          class="width-100"
          [formControl]="contactInfoControl"
          cdkAutosizeMinRows="3"
          class="input-section">
        </textarea>
      </div>
      <div fxLayout="column" fxLayoutGap="16px" class="width-100">
        <ng-container *ngIf="user; else infoView">
          <p class="margin-0">Attachment</p>
          <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop>
            <input
              hidden
              type="file"
              #fileInput
              (change)="uploadFile($event)"
              accept=".pdf,image/*" />
            <ng-container *ngIf="loadingMedia; else uploadIcon">
              <uni-loader [radius]="30"></uni-loader>
            </ng-container>
            <ng-template #uploadIcon>
              <i class="upload-icon icon-upload"></i>
            </ng-template>
            <span class="upload-text sparro-grey-900">
              {{ uploadProgress ? uploadingMessage : mediaName }}
            </span>
            <span class="upload-text sparro-grey-900">
              {{ uploadMessage }}
            </span>
          </div>
        </ng-container>
        <ng-template #infoView>
          <p class="margin-0 sparro-grey-800 width-100">
            <span class="font-bold">Note:</span> To add attachment please login/signup
          </p>
        </ng-template>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="width-100">
        <uni-state-button default="Reset" button_class="secondary" (click)="reset()">
        </uni-state-button>
        <uni-state-button
          default="Submit"
          button_class="primary"
          (click)="requestToContact()"
          [disabled]="!canSubmit()">
        </uni-state-button>
      </div>
    </form>
  </div>
</div>
<uni-public-footer></uni-public-footer>
