import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@about/app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@sparro/app/material.module';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { CardsModule } from '@sparro/app/shared/components/cards/cards.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { JurorsCommentComponent } from './jurors-comment/jurors-comment.component';
import { KeyFeaturesComponent } from './key-features/key-features.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';
import { TestimoniesComponent } from './testimonies/testimonies.component';
import { PopoverCardsModule } from '@sparro/app/shared/components/popover-cards/popover-cards.module';

@NgModule({
  declarations: [
    LandingPageComponent,
    KeyFeaturesComponent,
    JurorsCommentComponent,
    ContactUsComponent,
    TestimoniesComponent
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    MaterialModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    CardsModule,
    ImagesModule,
    ParallaxScrollModule,
    PipesModule,
    SharedModule,
    LoaderModule
  ],
  providers: [PopoverCardsModule]
})
export class LandingPageModule {}
