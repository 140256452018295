<div class="cover-wrapper relative">
  <div class="cover-container sparro-white">
    <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
    <div fxLayout="column" fxLayoutAlign="center center" class="review-container">
      <h1 class="margin-bottom-16 title" *ngIf="smallView">Hear it from the experts</h1>
      <div fxLayout="row" class="review-section" fxLayoutAlign="space-evenly center">
        <div fxLayout="column" fxLayoutGap="64px" class="text-section">
          <h1 class="margin-0 title" *ngIf="!smallView">Hear it from the experts</h1>
          <div fxlayout="column" fxLayoutgap="40px">
            <uni-image
              class="margin-bottom-10"
              alt="feature-img"
              src="https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature8.svg"
              classes="logo">
            </uni-image>
            <p class="comment">“Your design ideas deserve more than your portfolio”</p>
            <div fxlayout="column">
              <p class="margin-0 font-bold font-md">Scarlett Johansson</p>
              <p class="margin-0 font-bold font-md">CEO at Xion</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="image-section">
          <uni-image
            alt="testimony-cover"
            src="https://uni-common-assets.s3.us-east-1.amazonaws.com/testimony/testimonyCover.svg"
            classes="reviewer-image">
          </uni-image>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="dot-container">
        <i class="icon-arrow-left-bold arrow cursor-pointer"></i>
        <i class="dot active"></i>
        <i class="dot"></i>
        <i class="dot"></i>
        <i class="dot"></i>
        <i class="dot"></i>
        <i class="icon-arrow-right-bold arrow cursor-pointer"></i>
      </div>
    </div>
    <div class="featuredon-container">
      <ng-container *ngIf="smallView; else singleFlexView">
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages.slice(0, 4); let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages.slice(4, 8); let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #singleFlexView>
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages; let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="testimony-container margin-top-20" fxLayout="column" fxLayoutGap="64px">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" class="text-center">
    <h2 class="margin-0 title font-bolder">Reviews</h2>
    <p class="margin-0 font-md">
      Read reviews from a community of 200k+ professionals across the globe
    </p>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" style="gap: 10px">
    <ng-container *ngFor="let host of hosts">
      <button
        mat-button
        (click)="changeActiveHost(host)"
        [class]="activeHost.id == host.id ? 'active-btn font-normal' : 'host-btn font-normal'">
        {{ host.title }}
      </button>
    </ng-container>
  </div>
  <div class="card-container">
    <ng-container *ngFor="let testimony of testimonies">
      <ng-container *ngIf="testimony.featured; else defaultView">
        <div #testimonySection class="featured-testimony-card">
          <div fxLayout="column">
            <uni-user-avatar [user]="testimony.user" avatarClass="featured-user-img">
            </uni-user-avatar>
            <div fxLayout="column" fxLayoutGap="16px" class="padding-2">
              <p class="margin-0 font-bold sparro-grey-800 comment">“{{ testimony.comment }}</p>
              <div fxLayout="column" fxLayoutGap="5px">
                <a [href]="getProfileUrl(testimony.user.username)" class="no-decor">
                  <p class="username sparro-grey-800 margin-0">
                    {{ testimony.user.first_name | titlecase }}
                    {{ testimony.user.last_name | titlecase }}
                  </p>
                </a>
                <p class="user-detail sparro-grey-800 margin-0">
                  {{ testimony.user.bio | titlecase }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #defaultView>
        <div #testimonySection class="testimony-card padding-2">
          <div fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
              <uni-user-avatar [user]="testimony.user" avatarClass="user-img"> </uni-user-avatar>
              <div fxLayout="column" fxLayoutGap="5px">
                <a [href]="getProfileUrl(testimony.user.username)" class="no-decor">
                  <p class="username sparro-grey-800 margin-0">
                    {{ testimony.user.first_name | titlecase }}
                    {{ testimony.user.last_name | titlecase }}
                  </p>
                </a>
                <p class="user-detail sparro-grey-800 margin-0">
                  {{ testimony.user.bio | titlecase }}
                </p>
              </div>
            </div>
            <p class="margin-0 font-bold sparro-grey-800 comment">“{{ testimony.comment }}</p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<uni-public-footer></uni-public-footer>
