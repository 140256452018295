import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';

import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

import { JournalService } from '@about/app/core/api/journal/journal.service';
import { FEATURES, Image, IMAGES, SHOWCASE } from '@about/app/core/configs/landing.config';
import { CompetitionService } from '@about/core/api/competition/competition.service';
import { environment } from '@about/env/environment';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { AuthService } from '@about/app/core/api/auth/auth.service';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { Blog } from '@sparro/app/core/models/blog.model';
import { Competition } from '@sparro/app/core/models/competition.model';
import { Juror, User } from '@sparro/app/core/models/user.model';
import _ from 'lodash';

export class IconAndTitle {
  icon: string;
  title: string;
}

export class Reviewer {
  name: string;
  avatar_url: string;
  designation: string;
}

export class Testimony {
  user: Reviewer;
  content: string;
}

export class FeaturedBulletin {
  title: string;
  desc: string;
  info: string;
  image: {
    uuid: string;
    url: string;
    fileType: string;
  };
  archived: boolean = false;
  host: {
    name: string;
  };
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  partners: string[] = SHOWCASE.PARTNERS;
  featureImages: string[] = FEATURES.IMAGES;
  productFeatureCarousel: string[] = FEATURES.PRODUCTS;
  businessFeatures: IconAndTitle[] = FEATURES.BUSINESS;
  institutionFeatures: IconAndTitle[] = FEATURES.INSTITUTION;
  testimonies: Testimony[] = SHOWCASE.TESTIMONIES;

  showCarousel: boolean = false;
  smallView: boolean = false;
  mobileView: boolean = false;
  screenBelowLarge: boolean = false;
  carouselVisibleIndex: number = 0;
  testimonyVisibleIndex: number = 0;
  competitions: Competition[] = [];
  membersCount: number = 0;
  jurors: Juror[];
  journals: Blog[] = [];
  user: User;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  featuredBulletin: FeaturedBulletin;
  membershipOptimizedImage: Image;
  pageNumber: number = 1;

  @ViewChild('memberCounterSection') memberCounterSection: ElementRef;
  @ViewChild('videoSection') videoSection: ElementRef;
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChildren('cards') cards: QueryList<any>;
  @ViewChildren('jurorContainer') jurorContainer: QueryList<any>;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private competitionService: CompetitionService,
    private imageOptimization: ImageOptimizerService,
    public metaService: MetaService,
    private journalService: JournalService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.autoPlayCarousel();
    this.autoPlayTestimonies();
    this.getCompetitions();
    this.getJurors();
    this.getJournals();
    this.getFeaturedBulletins();
    this.membershipOptimizedImage = new Image(
      this.renderImage(IMAGES.MEMBERSHIP.src, 400, 400),
      IMAGES.MEMBERSHIP.alt
    );
    this.testimonies.forEach(testimony => {
      testimony.user.avatar_url = this.renderImage(testimony.user.avatar_url, 400, 400);
    });
    this.productFeatureCarousel.forEach(feature => {
      feature = this.renderImage(feature, 450, 450);
    });
  }

  getFeaturedBulletins() {
    this.authService.getFeaturedBulletins('Home').subscribe((response: any) => {
      this.featuredBulletin = response[_.random(0, response.length - 1)];
      this.featuredBulletin.image.url = this.renderImage(
        this.featuredBulletin.image.url,
        window.innerWidth,
        ''
      );
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([
        BREAKPOINTS.below.xl,
        BREAKPOINTS.below.lg,
        BREAKPOINTS.below.md,
        BREAKPOINTS.below.sm
      ])
      .subscribe(result => {
        if (
          (result.breakpoints[BREAKPOINTS.below.xl] &&
            !result.breakpoints[BREAKPOINTS.below.sm]) !== this.showCarousel
        ) {
          this.showCarousel = !this.showCarousel;
        }
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
        this.screenBelowLarge = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
      });
  }

  getJournals(): void {
    this.journalService.getJournalsByUserId(944).subscribe((response: any) => {
      this.journals = response.results.slice(0, 4);
      this.journals.forEach(journal => {
        journal.image = this.renderImage(journal.image, 450, 450);
      });
    });
  }

  getLaunchPageUrl() {
    return `${environment.aboutUrl}about/business/host`;
  }

  getMembershipPageUrl() {
    return `${environment.aboutUrl}about/designers/membership`;
  }

  getJurors(): void {
    this.competitionService.getJurors(this.pageNumber).subscribe((response: any) => {
      this.jurors = response.results;
      this.autoShuffleJurors();
    });
  }

  getCompetitions(): void {
    this.competitionService.getOngoingCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  renderImage(image, width, height): string {
    return this.imageOptimization.renderImage(image, width, height);
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  onWindowScroll(): void {
    const windowTop = window.pageYOffset;
    const windowBottom = window.innerHeight + window.pageYOffset;

    if (this.videoSection) {
      const videoSectionYcenter =
        this.videoSection.nativeElement.offsetTop +
        this.videoSection.nativeElement.offsetHeight / 2;
      if (window.innerWidth > 960) {
        this.videoSection.nativeElement.style.width =
          windowBottom < videoSectionYcenter
            ? '50vw'
            : `${(windowBottom - videoSectionYcenter) * 0.3}vw`;
      }

      if (
        windowTop + window.innerHeight / 2 > videoSectionYcenter &&
        !this.videoContainer.nativeElement.style.top
      ) {
        this.videoContainer.nativeElement.style.top = `${
          this.videoContainer.nativeElement.offsetTop - windowTop
        }px`;
        this.videoContainer.nativeElement.style.position = 'sticky';
      } else if (windowTop + window.innerHeight / 2 <= videoSectionYcenter) {
        this.videoContainer.nativeElement.style.top = '';
        this.videoContainer.nativeElement.style.position = 'unset';
      }
    }

    if (this.memberCounterSection) {
      const counterSectionYcenter =
        this.memberCounterSection.nativeElement.offsetTop +
        this.memberCounterSection.nativeElement.offsetHeight / 2;
      if (
        (counterSectionYcenter < windowBottom || windowTop < counterSectionYcenter) &&
        !this.membersCount
      ) {
        this.updateMembersCount();
      }
      if (windowTop > counterSectionYcenter || counterSectionYcenter > windowBottom) {
        this.clearCount();
      }
    }

    this.cards.forEach(card => {
      const cardYMid = card.nativeElement.offsetTop + card.nativeElement.offsetHeight / 2;
      card.nativeElement.style.transition = '0.4s';
      card.nativeElement.style.opacity =
        windowTop < card.nativeElement.offsetTop + card.nativeElement.offsetHeight * 0.75 &&
        windowBottom > cardYMid
          ? '1'
          : '0';
    });
  }

  autoPlayCarousel(): void {
    setTimeout(() => {
      this.nextCarouselSlide();
      this.autoPlayCarousel();
    }, 3000);
  }

  autoShuffleJurors(): void {
    if (window.innerWidth > 960) {
      setTimeout(() => {
        const indexArray = [
          Math.floor(Math.random() * 5),
          Math.floor(Math.random() * 5) + 6,
          Math.floor(Math.random() * 5) + 12,
          Math.floor(Math.random() * 6) + 18
        ];
        this.jurorContainer.forEach((juror, index) => {
          if (indexArray.includes(index)) {
            juror.nativeElement.style.opacity = '0';
          }
        });
        setTimeout(() => {
          [
            this.jurors[indexArray[0]],
            this.jurors[indexArray[1]],
            this.jurors[indexArray[2]],
            this.jurors[indexArray[3]]
          ] = [
            this.jurors[indexArray[3]],
            this.jurors[indexArray[2]],
            this.jurors[indexArray[1]],
            this.jurors[indexArray[0]]
          ];
          this.jurorContainer.forEach((juror, index) => {
            if (indexArray.includes(index)) {
              juror.nativeElement.style.opacity = '1';
            }
          });
        }, 800);
        this.autoShuffleJurors();
      }, 3000);
    }
  }

  autoPlayTestimonies(): void {
    if (window.innerWidth > 960) {
      setTimeout(() => {
        this.nextTestimonySlide();
        this.autoPlayTestimonies();
      }, 8000);
    }
  }

  previousTestimonySlide(): void {
    this.testimonyVisibleIndex--;
    this.testimonyVisibleIndex =
      this.testimonyVisibleIndex < 0 ? this.testimonies.length - 1 : this.testimonyVisibleIndex;
  }

  nextCarouselSlide(): void {
    this.carouselVisibleIndex++;
    this.carouselVisibleIndex %= this.showCarousel
      ? this.productFeatureCarousel.length / 2
      : this.productFeatureCarousel.length;
  }

  nextTestimonySlide(): void {
    this.testimonyVisibleIndex++;
    this.testimonyVisibleIndex %= this.testimonies.length;
  }

  clearCount(): void {
    this.membersCount = 0;
  }

  updateMembersCount(): void {
    const count = +this.membersCount;
    const inc = 3;

    if (count < 200) {
      this.membersCount = count + inc;
      setTimeout(() => {
        this.updateMembersCount();
      }, 1);
    } else {
      this.membersCount = 200;
    }
  }

  getJournalFeedUrl(): string {
    return `${environment.postUrl}`;
  }

  getJournalDetailUrl(journal: Blog): string {
    return `${environment.postUrl}${journal.slug}`;
  }

  getSignUpUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-up&continue=${window.location.href}`;
  }

  getCompetitionFeedUrl(): string {
    return `${environment.competitionUrl}`;
  }

  openForm(formType): void {
    formType === 'contributionForm'
      ? (this.contributionForm = true)
      : (this.competitionLaunchForm = true);
    this.stopScrolling();
  }

  stopScrolling(): void {
    window.onscroll = function () {
      window.scrollTo(window.scrollX, window.scrollY);
    };
  }

  updateMetaInfo(): void {
    const title = 'Showcase your creative potential on real world design competitions';
    const description = `A community-driven platform for designers to participate in design competition in the field of architecture,
      urban design, graphic design, photography. Get feedback from industry experts to improve your skills,
      build portfolios and win rewards.`;
    const cover = IMAGES.COVER.src;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
