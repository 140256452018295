import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@about/env/environment';
import { map } from 'rxjs/operators';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = null;

  constructor(private http: HttpClient, private metaService: MetaService) {
    this.baseUrl = `${environment.serverUrl}`;
  }

  getAuthUser() {
    return this.http.get(this.baseUrl + 'api/v2/profiles/auth/').pipe(
      map(response => {
        return this.metaService.decodeApiResponse(response);
      })
    );
  }

  isAuthenticatedServer() {
    return this.http.get(this.baseUrl + 'api/v2/is_authenticated/');
  }

  getCsrf() {
    return this.http.get(this.baseUrl + 'csrf/');
  }

  logout() {
    const postData = {};
    return this.http.post(this.baseUrl + 'rest-auth/logout/', postData);
  }

  getFeaturedBulletins(host: string) {
    return this.http.get(this.baseUrl + 'api/v2/bulletins/?host=' + host);
  }
}
