import { CommentService } from '@about/app/core/api/comment/comment.service';
import { environment } from '@about/env/environment';
import { Component, HostListener, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NeoComment } from '@sparro/app/core/api/comment/comment.model';

export class ProjectComment extends NeoComment {
  project: {
    slug: string;
    title: string;
  };
  score: number;
}

@Component({
  selector: 'app-jurors-comment',
  templateUrl: './jurors-comment.component.html',
  styleUrls: ['./jurors-comment.component.scss']
})
export class JurorsCommentComponent implements OnInit {
  @Input() showFixedCard: boolean = false;
  juryComments: ProjectComment[];

  @ViewChildren('commentSection') comments: QueryList<any>;

  constructor(private commentService: CommentService) {}

  ngOnInit(): void {
    this.getJuryComments();
  }

  getJuryComments(): void {
    this.commentService.getJuryComment('neo_projects').subscribe((response: ProjectComment[]) => {
      this.juryComments = response;
    });
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  checkCardVisibility(): void {
    if (window.innerWidth > 960) {
      const windowTop = window.pageYOffset;
      const windowBottom = window.innerHeight + window.pageYOffset;
      this.comments.forEach(comment => {
        const commentYMid =
          comment.nativeElement.offsetTop + comment.nativeElement.offsetHeight / 2;
        comment.nativeElement.style.opacity =
          windowTop < commentYMid && windowBottom > commentYMid ? '1' : '0';
      });
    }
  }

  getProjectUrl(slug: string): string {
    return `${environment.projectUrl}${slug}`;
  }
}
