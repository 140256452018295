import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sparro/env/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getcontactCategories() {
    return this.http.get(this.baseUrl + 'contact_category/');
  }

  createContactRequest(postData) {
    return this.http.post(this.baseUrl + 'contacts/', postData);
  }
}
